import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  Pagination,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import CancelIcon from '@mui/icons-material/Cancel';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import React, {
  ChangeEvent,
  DragEvent,
  Dispatch,
  MouseEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router';
import moment from 'moment';
import { useSession, xapi } from '../../../hooks/session';
import { debouncehook } from '../../../hooks/debouncehook';
import {
  AddGroupTextField,
  CreatePaper,
  DraggablePaper,
  RowsCell,
  MultilineTextField,
  NoneBorderChip,
  TermPaper,
} from '../../styles/Styles';
import { newUuidId, SwUuidCreate } from '../../commonComponent/SwUuidCreate';
import { SwImageSetting } from '../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { DataportalMultiConnect } from '../../commonComponent/DataportalMultiConnect';
import useLocalStorage from '../../../hooks/storage';
import {
  checkNaN,
  commaFormat,
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import {
  ButtonBox,
  TemplateCodeBox,
  TemplateCodeTitle,
  ContentTitle,
  MarginButton,
} from './ContentManageStyles';
import { IUploadFile } from '../../../models/common';
import { postUploadFile } from '../../../common/api/ApiCommon';
import {
  generateContentManageMagazineHtml,
  getContentManageContentDetailInfo,
  registerContentManageContent,
} from '../../../common/api/ApiContentManage';
import { RESULT_OK } from '../../../common/resultCode';
import { uploadFile } from '../../../common/api/ApiUpload';
import { SwSearchTarget } from '../../commonComponent/SwSearchTarget';
import { CommunityConnect } from '../../commonComponent/CommunityConnect';
import { SwSelectTags } from '../../commonComponent/SwSelectTags';
import { SwTemplateCodeConnect } from '../../commonComponent/program/SwTemplateCodeConnect';
import { SwInputLink } from '../../commonComponent/program/SwInputLink';
import { SwSelectKeyword } from '../../commonComponent/program/SwSelectKeyword';
import {
  KEY_CONTENT_MAMANGE_CONTENT_DETAIL_INFO,
  KEY_PROGRAM_TEMPLATE_CODE_LIST,
} from '../../../common/key';
import { getProgramTemplateCodeList } from '../../../common/api/ApiProgram';
import { getFileSize, getMagazineSubType } from '../../../common/helperProgram';
import {
  IMAGE_SIZE_10MB_MESSAGE,
  MAX_UPLOAD_FILE_SIZE,
} from '../../commonComponent/program/SwProgramCommon';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { SwConnectMission } from '../../commonComponent/program/SwConnectMission';

const WALKING_CALORIE = 1.7;
const INIT_UNIT_SELECTOR = 'g';
const intakeUnitList = ['g', 'mg', 'ml', 'mcg'];
const keywordIntakeUnitList = ['g', 'mg', '㎍', 'ml'];
const difficultyList = [
  { name: '상', value: 1 },
  { name: '중', value: 2 },
  { name: '하', value: 3 },
];

interface Props {
  contentManageInfo: any;
  setContentManageInfo: Dispatch<React.SetStateAction<any>>;
  reloadContentManage: (id: any) => void;
}

export const ContentManageContentMagazineForm: React.FC<Props> = ({
  contentManageInfo,
  setContentManageInfo,
  reloadContentManage,
}) => {
  const location = useLocation();
  const { loginVal } = useSession();
  const [categoryId, setCategoryId] = useState<string>('');
  const [parentCategoryId, setParentCategoryId] = useState<string>('');
  const [subType, setSubType] = useState<number>(4);
  const [contentId, setContentId] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [intake, setIntake] = useState<number>(0);
  const [selectUnit, setSelectUnit] = useState<any>(INIT_UNIT_SELECTOR);
  const [difficulty, setDifficulty] = useState<number>(1);
  const [exerciseTime, setExerciseTime] = useState<number>(10);
  const [calorie, setCalorie] = useState<number>(0);
  const [keywordSelectUnit, setKeywordSelectUnit] = useState<any>('select');
  const [description, setDescription] = useState<string>('');
  const [relatedMissions, setRelatedMissions] = useState<any>();
  const [savedRelatedMissions, setSavedRelatedMissions] = useState<any>();
  const [thumbImage, setThumbImage] = useState<string>('');
  const [thumbImageFile, setThumbImageFile] = useState<any>(null);
  const [htmlFileName, setHtmlFileName] = useState<string>('');
  const [uploadMagazineImage, setUploadMagazineImage] = useState<any[]>([]);
  const [uploadMagazineFile, setUploadMagazineFile] = useState<any[]>([]);
  const [uploadMagazineLink, setUploadMagazineLink] = useState<any[]>([]);
  const [linkInfo, setLinkInfo] = useState<any>();
  const [imageUrlPrefix, setImageUrlPrefix] = useState<string>(
    loginVal.value.user.imageUrlPrefix
  );
  const [cloudFront, setCloudFront] = useState<string>(
    loginVal.value.user.cloudFront
  );
  const [customContentType, setCustomContentType] = useState<string>('none');
  const [seq, setSeq] = useState<number>(0);
  const [status, setStatus] = useState<number>(1);
  const [selectedContentManage, setSelectedContentManage] =
    useState<boolean>(false);
  const [findTemplateCode, setFindTemplateCode] = useState<string>('');
  const [connectTemplateCode, setConnectTemplateCode] = useState<any[]>([]);
  const [targetInfo, setTargetInfo] = useState<any>();
  const [keywordInfo, setKeywordInfo] = useState<any[]>([]);
  const [highKeywordInfo, setHighKeywordInfo] = useState<any[]>([]);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openLink, setOpenLink] = useState<boolean>(false);
  const [openKeyword, setOpenKeyword] = useState<boolean>(false);
  const keywordItems = document.querySelectorAll('.keyword_draggable');
  const highKeywordItems = document.querySelectorAll('.high_keyword_draggable');
  const [listEvent, setListEvent] = useState<any>({
    currentDrag: null,
    over: null,
    clicked: null,
    list: [],
  });
  const [highListEvent, setHighListEvent] = useState<any>({
    currentDrag: null,
    over: null,
    clicked: null,
    list: [],
  });
  const [templateCodeList, setTemplateCodeList] = useState<any[]>([]);

  const { refetch: contentManageContentDetailRefetch } = useQuery(
    KEY_CONTENT_MAMANGE_CONTENT_DETAIL_INFO,
    () => {
      console.log('contentId: ', contentId);
      const newRequest = {
        type: 2, // magazine
        contentId: contentId !== null ? contentId : '',
      };
      if (contentId !== undefined && contentId !== null && contentId.length > 0)
        return getContentManageContentDetailInfo(newRequest);
      return null;
    },
    {
      onSuccess: res => {
        console.log('CMS content detailInfo :', res);
        if (res !== null && res.content !== null) {
          const newContent = res.content;
          setSavedRelatedMissions(
            newContent.relatedMissions ? newContent.relatedMissions : []
          );
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const {
    mutate: registerContentManageContentMutation,
    isError: isMutateError,
  } = useMutation(registerContentManageContent, {
    onSuccess: res => {
      console.log('registerContentManageContent res:', res);
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setAlertMessage('저장되었습니다.');
        reloadContentManage({
          id: categoryId,
          parentCategoryId: parentCategoryId,
        });
      } else {
        setAlertOpen(true);
        setAlertMessage(
          `저장하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setAlertMessage(
        '콘텐츠를 생성하는동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const {
    mutate: generateContentManageMgazineHtmlMutation,
    isError: isHtmlMutateError,
  } = useMutation(generateContentManageMagazineHtml, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setAlertMessage('생성되었습니다.');
        setHtmlFileName(res.resultCodeMsg);
      } else {
        setAlertOpen(true);
        setAlertMessage(
          `생성하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
        setHtmlFileName(
          contentManageInfo.url !== null ? contentManageInfo.url : ''
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setAlertMessage(
        'Html 파일을 생성하는동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
      setHtmlFileName(
        contentManageInfo.url !== null ? contentManageInfo.url : ''
      );
    },
  });

  const addContentManageContent = (
    newUploadUrl: string,
    newMagazineUploadUrl: any[]
  ) => {
    const newThumbImage =
      thumbImageFile !== null
        ? newUploadUrl
        : thumbImage !== null || thumbImage !== ''
        ? thumbImage
        : '';
    const newImageList =
      newMagazineUploadUrl.length > 0
        ? newMagazineUploadUrl.map((item: any, idx: number) => {
            const newData: any = {
              idx: idx,
              image: item.uploadUrl,
              type: uploadMagazineLink[idx].linkType,
              url: uploadMagazineLink[idx].link,
              fname: uploadMagazineImage[idx].fname,
            };
            return newData;
          })
        : '';
    const newIdx =
      contentManageInfo.idx !== undefined && contentManageInfo.idx !== null
        ? contentManageInfo.idx
        : -1;
    const tmpKeywordList = [...keywordInfo, ...highKeywordInfo];
    const newKeywordList = tmpKeywordList.map((item: any) => {
      const newData: any = {
        t: item.title,
        h: item.isHigh,
      };
      if (item.showUnit) {
        newData.v = item.intake !== undefined ? item.intake : '';
        newData.u =
          item.unit !== undefined && item.unit !== 'select' ? item.unit : '';
      }
      return newData;
    });

    const newCustomContent =
      targetInfo !== undefined && targetInfo.length > 0
        ? targetInfo.map((item: any) => item.templateCode)
        : '';

    console.log('--> relatedMissions:', relatedMissions);
    const newRelatedMissions: any[] = [];
    if (relatedMissions !== null) {
      relatedMissions.survey !== undefined &&
        relatedMissions.survey.map((mission: any, idx: number) => {
          newRelatedMissions.push({
            contentId: contentId,
            relatedContentId: mission.relatedContentId,
            type: mission.type,
            seq: idx + 1,
            path: mission.path,
          });
        });
      relatedMissions.quiz !== undefined &&
        relatedMissions.quiz.map((mission: any, idx: number) => {
          newRelatedMissions.push({
            contentId: contentId,
            relatedContentId: mission.relatedContentId,
            type: mission.type,
            seq: idx + 1,
            path: mission.path,
          });
        });
      relatedMissions.lifestyle !== undefined &&
        relatedMissions.lifestyle.map((mission: any, idx: number) => {
          newRelatedMissions.push({
            contentId: contentId,
            relatedContentId: mission.relatedContentId,
            type: mission.type,
            seq: idx + 1,
            path: mission.path,
          });
        });
    }

    const newData: any = {
      idx: newIdx,
      type: 'magazine',
      categoryId: categoryId,
      contentId: contentId,
      title: title,
      intake: intake,
      unit: selectUnit,
      difficulty: difficulty,
      exerciseTime: exerciseTime,
      calorie: calorie,
      keyword: newKeywordList.length > 0 ? JSON.stringify(newKeywordList) : '',
      image: newImageList.length > 0 ? JSON.stringify(newImageList) : '',
      thumbImage: newThumbImage,
      customContent:
        newCustomContent.length > 0 ? JSON.stringify(newCustomContent) : '',
      status: status,
      seq: seq,
      relatedMissions: newRelatedMissions,
    };
    console.log('addContentManageContent:', newData);
    registerContentManageContentMutation(newData);
  };

  const handleUploadFile = (targetFile: any) => {
    // const newFile = [...uploadMagazineFile, targetFile];
    // setUploadMagazineFile(newFile);
    const newImage = [
      ...uploadMagazineImage,
      { name: targetFile.name, file: targetFile, fname: targetFile.name },
    ];
    setUploadMagazineImage(newImage);
    const newLink = [...uploadMagazineLink, { linkType: '', link: '' }];
    setUploadMagazineLink(newLink);
  };

  const deleteUploadMagazine = (idx: number) => {
    const newFile = uploadMagazineImage.filter(
      (item: any, i: number) => i !== idx
    );
    setUploadMagazineImage(newFile);
    const newLink = uploadMagazineLink.filter(
      (item: any, i: number) => i !== idx
    );
    setUploadMagazineLink(newLink);
  };

  const dragStart = (evt: DragEvent<HTMLDivElement>) => {
    setListEvent({
      ...listEvent,
      currentDrag: evt.currentTarget.dataset.position,
      list: keywordInfo,
    });
    evt.currentTarget.style.border = '1px solid #757575';
  };

  const drop = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';

    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (list: any, idx: number) => idx.toString() !== listEvent.currentDrag
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    setKeywordInfo(newList);

    setListEvent({
      currentDrag: null,
      over: null,
      ...listEvent,
    });
  };

  const dragEnd = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    keywordItems.forEach(item => item.classList.remove('drag_over'));
    // changeCommunityGroupOrder();
  };

  const dragEnter = (evt: DragEvent<HTMLDivElement>) => {
    const newOver = evt.currentTarget.dataset.position;
    setListEvent({ ...listEvent, over: newOver });
  };

  const dragOver = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.currentTarget.classList.add('drag_over');
    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (_list: any, idx: number) => idx.toString() !== newCurrent
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    listEvent.over !== newOver && setKeywordInfo(newList);
  };

  const dragLeave = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    keywordItems.forEach(item => item.classList.remove('drag_over'));
  };

  // High Keyword
  const dragStartHigh = (evt: DragEvent<HTMLDivElement>) => {
    setHighListEvent({
      ...highListEvent,
      currentDrag: evt.currentTarget.dataset.position,
      list: highKeywordInfo,
    });
    evt.currentTarget.style.border = '1px solid #757575';
  };

  const dropHigh = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';

    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = highListEvent.currentDrag;
    const filterList = highListEvent.list.filter(
      (list: any, idx: number) => idx.toString() !== highListEvent.currentDrag
    );
    const newList = [
      ...filterList.slice(0, newOver),
      highListEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    setHighKeywordInfo(newList);

    setHighListEvent({
      currentDrag: null,
      over: null,
      ...highListEvent,
    });
  };

  const dragEndHigh = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    highKeywordItems.forEach(item => item.classList.remove('drag_over_high'));
    // changeCommunityGroupOrder();
  };

  const dragEnterHigh = (evt: DragEvent<HTMLDivElement>) => {
    const newOver = evt.currentTarget.dataset.position;
    setHighListEvent({ ...highListEvent, over: newOver });
  };

  const dragOverHigh = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.currentTarget.classList.add('drag_over_high');
    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = highListEvent.currentDrag;
    const filterList = highListEvent.list.filter(
      (_list: any, idx: number) => idx.toString() !== newCurrent
    );
    const newList = [
      ...filterList.slice(0, newOver),
      highListEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    highListEvent.over !== newOver && setHighKeywordInfo(newList);
  };

  const dragLeaveHigh = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    highKeywordItems.forEach(item => item.classList.remove('drag_over_high'));
  };

  async function uploadMultipleFiles() {
    var newUploadUrl = '';
    if (thumbImageFile !== null) {
      const newUploadFile: any = await uploadFile('program', thumbImageFile);
      newUploadUrl = newUploadFile.data.uploadUrl.file.path;
    }

    const newUploadMagazineFile = await Promise.all(
      uploadMagazineImage.map(async (item: any, idx: number) => {
        var imageUrl = null;
        var newUploadFile: any = null;
        if (item.file !== null) {
          newUploadFile = await uploadFile('program', item.file);
        }

        return {
          uploadUrl:
            item.file !== null
              ? newUploadFile.data.uploadUrl.file.path
              : item.name,
        };
      })
    );

    addContentManageContent(newUploadUrl, newUploadMagazineFile);
  }

  const checkValid = () => {
    var val = 0;

    if (val === 0 && title.length === 0) val = 1;
    if (val === 0 && categoryId.length === 0) val = 4;

    if (val !== 0) {
      var message = '';
      switch (val) {
        case 1:
          message = '제목을 입력해주세요.';
          break;
        case 4:
          message = '카테고리를 선택해주세요';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setAlertMessage(message);
      setAlertOpen(true);
    }

    return val;
  };

  const saveData = () => {
    if (checkValid() !== 0) {
      console.log('invalid !!!');
      return false;
    }

    if (
      thumbImageFile !== undefined &&
      thumbImageFile !== null &&
      getFileSize(thumbImageFile) >= MAX_UPLOAD_FILE_SIZE
    ) {
      setOpenSnackbar(true);
      setAlertMessage(IMAGE_SIZE_10MB_MESSAGE);
      return false;
    }

    const newMagazineImages = uploadMagazineImage
      .filter(
        (item: any, idx: number) =>
          item.file !== undefined &&
          item.file !== null &&
          getFileSize(item.file) >= MAX_UPLOAD_FILE_SIZE
      )
      .filter(item => item === true);
    if (newMagazineImages.length > 0) {
      setOpenSnackbar(true);
      setAlertMessage(IMAGE_SIZE_10MB_MESSAGE);
      return false;
    }

    uploadMultipleFiles();
    return true;
  };

  const setupInfo = () => {
    console.log('contentManageInfo -> :', contentManageInfo);
    if (contentManageInfo !== undefined && contentManageInfo !== null) {
      if (contentManageInfo.save) {
        if (saveData() === false) return;
      }
      setContentId(
        contentManageInfo.contentId !== undefined &&
          contentManageInfo.contentId !== null
          ? contentManageInfo.contentId
          : newUuidId()
      );
      setCategoryId(
        contentManageInfo.categoryId !== undefined &&
          contentManageInfo.categoryId !== null
          ? contentManageInfo.categoryId
          : ''
      );
      setParentCategoryId(
        contentManageInfo.parentCategoryId !== undefined &&
          contentManageInfo.parentCategoryId !== null
          ? contentManageInfo.parentCategoryId
          : ''
      );
      setSubType(
        contentManageInfo.subType !== null ? contentManageInfo.subType : 4
      );
    }
  };
  const {
    data,
    isLoading,
    refetch: programTemplateCodeRefetch,
  } = useQuery(
    KEY_PROGRAM_TEMPLATE_CODE_LIST,
    () => {
      const newRequest = {
        status: 2, // 1: close, 2: open, 3: all
        page: 0,
        rowsPerPage: 0,
        searchKey: 'all',
        searchWord: '',
      };
      if (
        contentManageInfo.contentId !== undefined &&
        contentManageInfo.contentId !== null
      ) {
        return getProgramTemplateCodeList(newRequest);
      }
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null && res.templateCode !== undefined) {
          setTemplateCodeList(res.templateCode);
          setupInfo();
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const deleteUploadMagazineLink = (idx: number) => {
    const newData = [...uploadMagazineLink];
    newData[idx].link = '';
    setUploadMagazineLink(newData);
  };

  const generateMagazineHtml = () => {
    const names = uploadMagazineImage.map((item: any) => item.name);
    const newIdx =
      contentManageInfo.idx !== undefined && contentManageInfo.idx !== null
        ? contentManageInfo.idx
        : -1;
    const newData: any = {
      idx: newIdx,
      type: 'magazine',
      categoryId: categoryId,
      contentId: contentId,
      image: names !== null && names.length !== 0 ? JSON.stringify(names) : '',
    };
    generateContentManageMgazineHtmlMutation(newData);
  };

  const changeHandler = (mission: any) => {
    setRelatedMissions(mission);
  };

  useEffect(() => {
    console.log('keywordInfo:', keywordInfo);
  }, [keywordInfo]);

  useEffect(() => {
    if (
      linkInfo !== undefined &&
      linkInfo !== null &&
      linkInfo.seq !== undefined
    ) {
      const newData = { linkType: linkInfo.linkType, link: linkInfo.link };
      const newUploadLink = [...uploadMagazineLink];
      newUploadLink[linkInfo.seq] = newData;
      setUploadMagazineLink(newUploadLink);
      console.log('newUploadLink:', newUploadLink);
    }
  }, [linkInfo]);

  useEffect(() => {
    if (
      contentManageInfo.contentId !== undefined &&
      contentManageInfo.contentId !== null
    ) {
      setTitle(contentManageInfo.title);
      setIntake(contentManageInfo.intake);
      setSelectUnit(contentManageInfo.unit);
      setStatus(contentManageInfo.status === false ? 0 : 1);
      setSeq(contentManageInfo.seq);
      setThumbImage(contentManageInfo.thumbImage);
      setThumbImageFile(null);
      setSubType(
        contentManageInfo.subType !== null ? contentManageInfo.subType : 4
      );
      setDifficulty(contentManageInfo.difficulty);
      setExerciseTime(contentManageInfo.exerciseTime);
      setCalorie(contentManageInfo.calorie);
      setHtmlFileName(contentManageInfo.url);

      const tmpKeyword =
        contentManageInfo.keyword !== undefined &&
        contentManageInfo.keyword.length > 0
          ? JSON.parse(contentManageInfo.keyword)
          : [];
      const newKeyword = tmpKeyword
        .filter((item: any) => item.h === 0)
        .map((item: any) => {
          const newData: any = {
            intake: item.v !== undefined ? item.v : 0,
            unit: item.u !== undefined ? item.u : 'select',
            title: item.t,
            isHigh: item.h,
            showUnit: item.u !== undefined ? 1 : 0,
          };
          return newData;
        });
      const newHighKeyword = tmpKeyword
        .filter((item: any) => item.h === 1)
        .map((item: any) => {
          const newData: any = {
            intake: item.v !== undefined ? item.v : 0,
            unit: item.u !== undefined ? item.u : 'select',
            title: item.t,
            isHigh: item.h,
            showUnit: item.u !== undefined ? 1 : 0,
          };
          return newData;
        });
      setKeywordInfo(newKeyword);
      setHighKeywordInfo(newHighKeyword);

      const tmpImage =
        contentManageInfo.image !== undefined &&
        contentManageInfo.image.length > 0
          ? JSON.parse(contentManageInfo.image)
          : [];
      const newImage = tmpImage.map((item: any) => {
        const newData: any = {
          name: item.image,
          file: null,
          fname:
            item.fname === undefined || item.fname === '' ? '-' : item.fname,
        };
        return newData;
      });
      setUploadMagazineImage(newImage);
      const newImageLink = tmpImage.map((item: any) => {
        const newData: any = {
          linkType: item.type === undefined ? '' : item.type,
          link: item.url === undefined ? '' : item.url,
        };
        return newData;
      });
      setUploadMagazineLink(newImageLink);

      if (
        contentManageInfo.customContent !== undefined &&
        contentManageInfo.customContent.length > 0
      ) {
        setCustomContentType('exist');
        const tmpTemplateCode = JSON.parse(contentManageInfo.customContent);
        const newTemplateCode = tmpTemplateCode.map((item: any) => {
          const newCode = templateCodeList.filter(
            (code: any) => code.templateCode === item
          );
          console.log('newCode:', newCode);
          const newData: any = {
            templateCode: item,
            templateName:
              newCode !== undefined && newCode.length > 0
                ? newCode[0].templateName
                : '-',
          };
          return newData;
        });
        setConnectTemplateCode(newTemplateCode);
      }
      contentManageContentDetailRefetch();
    } else {
      setTitle('');
      setIntake(0);
      setSelectUnit(INIT_UNIT_SELECTOR);
      setDifficulty(1);
      setExerciseTime(0);
      setCalorie(0);
      setKeywordInfo([]);
      setHighKeywordInfo([]);
      setUploadMagazineImage([]);
      setDescription('');
      setStatus(1);
      setThumbImage('');
      setThumbImageFile(null);
      setCustomContentType('none');
      setUploadMagazineLink([]);
      setLinkInfo(null);
      setSeq(1);
      setHtmlFileName('');
      setSubType(4);
    }
  }, [contentId]);

  useEffect(() => {
    console.log('contentManageInfo -> :', contentManageInfo);
    if (
      contentManageInfo.contentId !== undefined &&
      contentManageInfo.contentId !== null
    ) {
      programTemplateCodeRefetch();
    } else {
      setupInfo();
    }
  }, [contentManageInfo]);

  return (
    <>
      <CreatePaper>
        <Box>
          <Typography className='title'>매거진 제목</Typography>
          <TextField
            sx={{ width: '48rem' }}
            value={title}
            onChange={(
              evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setTitle(evt.target.value.substring(0, 30))}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <span>{title?.length} / 30</span>
                </InputAdornment>
              ),
            }}
          />
          <Divider />
        </Box>
        {subType === 1 && (
          <Box>
            <Box className='flex_between'>
              <Typography className='title'>1회 섭취량</Typography>
            </Box>
            <TextField
              value={intake}
              onChange={(
                evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setIntake(checkNaN(evt.target.value, intake))}
            />
            <TextField
              sx={{ width: '10rem', mr: '1rem', ml: '1rem' }}
              select
              defaultValue={selectUnit}
              value={selectUnit}
              onChange={evt => setSelectUnit(evt.target.value)}
            >
              {intakeUnitList.map((option: any, i: number) => (
                <MenuItem key={`magazine_unit_${option}`} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <Divider />
          </Box>
        )}
        {subType === 2 && (
          <Box>
            <Box className='flex_between'>
              <Typography className='title'>난이도</Typography>
            </Box>
            <TextField
              sx={{ width: '10rem', mr: '1rem', ml: '1rem' }}
              select
              defaultValue={difficulty}
              value={difficulty}
              onChange={evt => setDifficulty(Number(evt.target.value))}
            >
              {difficultyList.map((option: any, i: number) => (
                <MenuItem
                  key={`magazine_unit_${option.value}`}
                  value={option.value}
                >
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <Divider />
          </Box>
        )}
        {subType === 3 && (
          <Box>
            <Box className='flex_between'>
              <Typography className='title'>운동 시간</Typography>
            </Box>
            <TextField
              value={exerciseTime}
              onChange={(
                evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => {
                setExerciseTime(checkNaN(evt.target.value, exerciseTime));
              }}
            />
            <Box className='flex_between'>
              <Typography className='title'>소모 칼로리</Typography>
            </Box>
            <TextField
              value={calorie}
              onChange={(
                evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setCalorie(checkNaN(evt.target.value, calorie))}
            />
            <Divider />
          </Box>
        )}
        <Box>
          <Box className='flex_between'>
            <Typography className='title'>키워드</Typography>
          </Box>
          <Box>
            <Button
              color='primary'
              variant='contained'
              onClick={() => {
                setOpenKeyword(true);
              }}
            >
              키워드 추가
            </Button>
          </Box>
        </Box>
        <Box>
          {keywordInfo.map((keyword: any, idx: number) => (
            <Box key={`drag_keyword_${idx}`} sx={{ display: 'flex' }}>
              <DraggablePaper
                className='keyword_draggable'
                id={idx.toString()}
                variant='outlined'
                onDragStart={dragStart}
                onDragEnd={dragEnd}
                onDragOver={dragOver}
                onDrop={drop}
                onDragLeave={dragLeave}
                onDragEnter={dragEnter}
                draggable
                data-position={idx}
                sx={{ width: '60rem' }}
              >
                <Box className='flex_between'>
                  <Box className='flex_start'>
                    <DragHandleIcon />
                    <Box sx={{ ml: '1rem', width: '15rem' }}>
                      <Typography>{keyword.title}</Typography>
                    </Box>
                  </Box>
                  <Box className='flex_end' sx={{ width: '40rem' }}>
                    {keyword.showUnit === 1 && (
                      <Box>
                        <TextField
                          sx={{ width: '10rem', mr: '1rem', ml: '1rem' }}
                          placeholder='수치입력'
                          value={
                            keyword.intake !== undefined ? keyword.intake : ''
                          }
                          onChange={(
                            evt: ChangeEvent<
                              HTMLTextAreaElement | HTMLInputElement
                            >
                          ) => {
                            const newKeywordInfo = [...keywordInfo];
                            newKeywordInfo[idx].intake = checkNaN(
                              evt.target.value,
                              keyword.intake
                            );
                            setKeywordInfo(newKeywordInfo);
                          }}
                        />
                        <TextField
                          sx={{ width: '10rem', mr: '1rem', ml: '1rem' }}
                          select
                          defaultValue='select'
                          value={
                            keyword.unit !== undefined ? keyword.unit : 'select'
                          }
                          onChange={evt => {
                            const newKeywordInfo = [...keywordInfo];
                            newKeywordInfo[idx].unit = evt.target.value;
                            setKeywordInfo(newKeywordInfo);
                          }}
                        >
                          <MenuItem key='magazine_unit_select' value='select'>
                            선택
                          </MenuItem>
                          {keywordIntakeUnitList.map(
                            (option: any, i: number) => (
                              <MenuItem
                                key={`magazine_keyword_unit_${option}`}
                                value={option}
                              >
                                {option}
                              </MenuItem>
                            )
                          )}
                        </TextField>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={`keyword_cb_${idx}`}
                              sx={{ color: '#808383' }}
                              onChange={e => {
                                if (e.currentTarget.checked) {
                                  keyword.isHigh = 1;
                                  const newHighKeywordInfo = [
                                    ...highKeywordInfo,
                                    keyword,
                                  ];
                                  setHighKeywordInfo(newHighKeywordInfo);

                                  const newKeywordInfo = keywordInfo.filter(
                                    (item: any, i: number) => i !== idx
                                  );
                                  setKeywordInfo(newKeywordInfo);
                                }
                              }}
                              checked={keyword.isHigh}
                            />
                          }
                          label='고함량 여부'
                        />
                      </Box>
                    )}
                    <Button
                      onClick={() => {
                        const newKeywordInfo = keywordInfo.filter(
                          (item: any, i: number) => i !== idx
                        );
                        setKeywordInfo(newKeywordInfo);
                      }}
                    >
                      <CancelIcon
                        sx={{ fontSize: '1.2rem', color: '#000000' }}
                      />
                    </Button>
                  </Box>
                </Box>
              </DraggablePaper>
            </Box>
          ))}
        </Box>
        {highKeywordInfo.length > 0 && (
          <Box sx={{ mt: '1rem' }}>
            <Typography>고함량 키워드</Typography>
            <Typography sx={{ color: '#999999' }}>
              앱 내에서 고함량으로 표시됩니다.
            </Typography>
          </Box>
        )}
        <Box>
          {highKeywordInfo.map((keyword: any, idx: number) => (
            <Box key={`drag_high_keyword_${idx}`} sx={{ display: 'flex' }}>
              <DraggablePaper
                className='high_keyword_draggable'
                id={idx.toString()}
                variant='outlined'
                onDragStart={dragStartHigh}
                onDragEnd={dragEndHigh}
                onDragOver={dragOverHigh}
                onDrop={dropHigh}
                onDragLeave={dragLeaveHigh}
                onDragEnter={dragEnterHigh}
                draggable
                data-position={idx}
                sx={{ width: '70%' }}
              >
                <Box className='flex_between'>
                  <Box className='flex_start'>
                    <DragHandleIcon />
                    <Box sx={{ ml: '1rem', width: '15rem' }}>
                      <Typography>{keyword.title}</Typography>
                    </Box>
                  </Box>
                  <Box className='flex_end'>
                    {keyword.showUnit === 1 && (
                      <Box>
                        <TextField
                          sx={{ width: '10rem', mr: '1rem', ml: '1rem' }}
                          placeholder='수치입력'
                          value={
                            keyword.intake !== undefined ? keyword.intake : ''
                          }
                          onChange={(
                            evt: ChangeEvent<
                              HTMLTextAreaElement | HTMLInputElement
                            >
                          ) => {
                            const newKeywordInfo = [...highKeywordInfo];
                            newKeywordInfo[idx].intake = checkNaN(
                              evt.target.value,
                              keyword.intake
                            );
                            setHighKeywordInfo(newKeywordInfo);
                          }}
                        />
                        <TextField
                          sx={{ width: '10rem', mr: '1rem', ml: '1rem' }}
                          select
                          defaultValue='select'
                          value={
                            keyword.unit !== undefined ? keyword.unit : 'select'
                          }
                          onChange={evt => {
                            const newKeywordInfo = [...highKeywordInfo];
                            newKeywordInfo[idx].unit = evt.target.value;
                            setHighKeywordInfo(newKeywordInfo);
                          }}
                        >
                          <MenuItem key='magazine_unit_select' value='select'>
                            선택
                          </MenuItem>
                          {keywordIntakeUnitList.map(
                            (option: any, i: number) => (
                              <MenuItem
                                key={`magazine_keyword_unit_${option}`}
                                value={option}
                              >
                                {option}
                              </MenuItem>
                            )
                          )}
                        </TextField>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={`keyword_cb_${idx}`}
                              sx={{ color: '#808383' }}
                              onChange={e => {
                                if (!e.currentTarget.checked) {
                                  keyword.isHigh = 0;
                                  const newKeywordInfo = [
                                    ...keywordInfo,
                                    keyword,
                                  ];
                                  setKeywordInfo(newKeywordInfo);

                                  const newHighKeywordInfo =
                                    highKeywordInfo.filter(
                                      (item: any, i: number) => i !== idx
                                    );
                                  setHighKeywordInfo(newHighKeywordInfo);
                                }
                              }}
                              checked={keyword.isHigh}
                            />
                          }
                          label='고함량 여부'
                        />
                      </Box>
                    )}
                    <Button
                      onClick={() => {
                        const newHighKeywordInfo = highKeywordInfo.filter(
                          (item: any, i: number) => i !== idx
                        );
                        setHighKeywordInfo(newHighKeywordInfo);
                      }}
                    >
                      <CancelIcon
                        sx={{ fontSize: '1.2rem', color: '#000000' }}
                      />
                    </Button>
                  </Box>
                </Box>
              </DraggablePaper>
            </Box>
          ))}
        </Box>
        <Divider />
        <Box>
          <Box className='flex_between'>
            <Typography className='title'>이미지</Typography>
          </Box>
          <Box className='flex_start'>
            <Button
              color='primary'
              variant='contained'
              onClick={() => {
                const newFile1 = document.getElementById('magazine_file_1');
                newFile1?.click();
              }}
            >
              파일 선택하기
            </Button>
            <Box className='flex_start'>
              <Button
                color='primary'
                variant='outlined'
                sx={{ ml: '2rem', mr: '2rem' }}
                onClick={() => {
                  setOpenConfirm(true);
                }}
              >
                매거진 HTML 생성하기
              </Button>
              {htmlFileName !== undefined &&
                htmlFileName !== null &&
                htmlFileName.length > 0 && (
                  <Link href={`${cloudFront}${htmlFileName}`} target='_blank'>
                    {`${cloudFront}${htmlFileName}`}
                  </Link>
                )}
            </Box>
          </Box>
          <Divider />
          <input
            className='nonedisplay'
            id='magazine_file_1'
            type='file'
            onChange={(evt: ChangeEvent<HTMLInputElement>) => {
              evt.target.files && handleUploadFile(evt.target.files[0]);
            }}
          />
        </Box>
        <Box>
          {uploadMagazineImage.map((item: any, idx: number) => (
            <Box className='flex_start' key={`upload_key_${idx}`}>
              <Typography className='title' sx={{ width: '20rem' }}>
                {item.fname}
              </Typography>
              <Typography className='title' sx={{ width: '40rem' }}>
                {`${item.name}`}
              </Typography>
              <Box sx={{ width: '12rem' }}>
                <Button
                  color='primary'
                  variant='outlined'
                  sx={{ m: '.5rem .5rem' }}
                  onClick={() => {
                    deleteUploadMagazine(idx);
                  }}
                >
                  삭제
                </Button>
                <Button
                  color='primary'
                  variant='outlined'
                  sx={{ m: '.5rem .5rem' }}
                  onClick={() => {
                    setLinkInfo((prevState: any) => ({
                      ...prevState,
                      link: '',
                      seq: idx,
                    }));
                    setOpenLink(true);
                  }}
                >
                  링크추가
                </Button>
              </Box>
              <Box className='flex_start' sx={{ width: '40rem' }}>
                <Typography className='title'>
                  {uploadMagazineLink[idx].link}
                </Typography>
                {uploadMagazineLink[idx].link !== null &&
                  uploadMagazineLink[idx].link !== '' && (
                    <CancelIcon
                      sx={{ fontSize: '1.2rem', color: '#000000' }}
                      onClick={() => {
                        deleteUploadMagazineLink(idx);
                      }}
                    />
                  )}
              </Box>
            </Box>
          ))}
          {uploadMagazineImage.length > 0 && <Divider />}
        </Box>
        <Box>
          <Typography className='title'>썸네일 이미지</Typography>
          <SwFileSetting
            image={
              thumbImageFile != null
                ? thumbImage
                : thumbImage !== ''
                ? `${imageUrlPrefix}${thumbImage}`
                : ''
            }
            setImage={setThumbImage}
            setFile={setThumbImageFile}
            file={thumbImageFile}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>맞춤 콘텐츠 여부</Typography>
          <RadioGroup
            row
            aria-labelledby='movie-row-radio-buttons-group-label'
            name='row-radio-buttons-group'
            value={customContentType}
            onChange={evt => {
              setCustomContentType(evt.target.value);
            }}
          >
            <FormControlLabel value='none' control={<Radio />} label='없음' />
            <FormControlLabel value='exist' control={<Radio />} label='있음' />
          </RadioGroup>
          {customContentType === 'exist' && (
            <SwTemplateCodeConnect
              findTemplateCode={findTemplateCode}
              setFindTemplateCode={setFindTemplateCode}
              connectTemplateCode={connectTemplateCode}
              setConnectTemplateCode={setConnectTemplateCode}
              setTargetInfo={setTargetInfo}
            />
          )}
          <Divider />
        </Box>
        <Box>
          <Box className='flex_between'>
            <Typography className='title'>순서</Typography>
          </Box>
          <TextField
            value={seq}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setSeq(checkNaN(evt.target.value, seq))}
          />
          <Divider />
        </Box>
        <Box>
          <SwConnectMission
            relatedMissions={savedRelatedMissions}
            changeHandler={changeHandler}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>상태</Typography>
          <TextField
            sx={{ width: '12rem' }}
            select
            defaultValue={1}
            value={status}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setStatus(Number(evt.target.value))}
          >
            <MenuItem key={0} value={0}>
              Close
            </MenuItem>
            <MenuItem key={1} value={1}>
              Open
            </MenuItem>
          </TextField>
          <Divider />
        </Box>
      </CreatePaper>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={alertMessage}
      />
      <SwInputLink
        open={openLink}
        onClose={() => setOpenLink(false)}
        linkInfo={linkInfo}
        setLinkInfo={setLinkInfo}
      />
      <SwSelectKeyword
        open={openKeyword}
        onClose={() => setOpenKeyword(false)}
        type={subType}
        keywordInfo={keywordInfo}
        setKeywordInfo={setKeywordInfo}
      />
      <SwConfirmDialog
        contents='매거진을 생성하시겠습니까? 이전에 생성된 범용 매거진은 *_bkup.html 파일로 저장됩니다.'
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          setHtmlFileName('');
          generateMagazineHtml();
          setOpenConfirm(false);
        }}
        confirm={1}
      />
    </>
  );
};
